import React from 'react';
import { useFormik } from 'formik';
import { Box, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomForm.scss';
import { CustomModalButton } from '../../../Utils/StyleComponents';

const TerminateProcessForm = ({ onSubmit, filePath }) => {
  // Use Formik hook
  const formik = useFormik({
    initialValues: { description: '', filename: '', filesha1: filePath },
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1" component="label" htmlFor={'description'}>
          {'Description (optional)'}
        </Typography>
        <TextField
          id={'description'}
          name={'description'}
          placeholder={'Enter Description'}
          sx={{
            width: '100%',
            color: 'black',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#000000', // Default color
              },
              '&:hover fieldset': {
                borderColor: '#000000', // On hover
              },
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: 1,
            },
          }}
          InputProps={{
            autoComplete: 'off', // Disables autocomplete
          }}
          value={formik.values.description || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Box>
      <Box mb={3}>
        <Typography variant="body1" component="label" htmlFor={'filename'}>
          {'File Name (optional)'}
        </Typography>
        <TextField
          id={'filename'}
          name={'filename'}
          placeholder={'Enter File Name'}
          sx={{
            width: '100%',
            color: 'black',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#000000', // Default color
              },
              '&:hover fieldset': {
                borderColor: '#000000', // On hover
              },
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: 1,
            },
          }}
          InputProps={{
            autoComplete: 'off', // Disables autocomplete
          }}
          value={formik.values.filename || ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Box>
      <Box mb={3}>
        <Typography variant="body1" component="label" htmlFor={'filesha1'}>
          {'File Sha1'}
        </Typography>
        <TextField
          id={'filesha1'}
          name={'filesha1'}
          sx={{
            width: '100%',
            color: 'black',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#000000', // Default color
              },
              '&:hover fieldset': {
                borderColor: '#000000', // On hover
              },
            },
            '& .MuiInputBase-input::placeholder': {
              opacity: 1,
            },
          }}
          InputProps={{
            autoComplete: 'off', // Disables autocomplete
            readOnly: true,
          }}
          value={formik.values.filesha1 || ''}
          spellCheck={false}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <CustomModalButton type="submit">Terminate</CustomModalButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
TerminateProcessForm.propTypes = {
  onSubmit: PropTypes.func,
  filePath: PropTypes.string,
};

export default TerminateProcessForm;
