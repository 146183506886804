import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomModalTitle, CustomTitle } from '../../../Utils/StyleComponents';
import PropTypes from 'prop-types';
import CustomForm from './CustomForm';

const ModalForm = (props) => {
  const handleSkip = () => {
    props?.handleClose(false);
  };
  const [formFields, setFormFields] = useState([]);
  useEffect(() => {
    let fields = [];
    switch (props.selected) {
      case 'COLLECT':
        fields = [
          {
            name: 'description',
            placeholder: 'Enter Discription',
            label: 'Description (optional)',
            required: false,
          },
          {
            name: 'filepath',
            placeholder: 'Enter File Path',
            label: 'File Path',
            required: true,
          },
        ];
        break;
      case 'END_PROCESS':
        fields = [
          {
            name: 'processcmd',
            placeholder: 'Enter Process Cmd',
            label: 'Process Cmd',
            required: true,
          },
        ];
        break;
      default:
        fields = [
          {
            name: 'description',
            placeholder: 'Enter Discription',
            label: 'Description (optional)',
            required: false,
          },
        ];
        break;
    }
    setFormFields(fields);
  }, [props?.selected]);
  const handleCountinue = (formValues) => {
    props?.handleClose(false);
    props?.handleResponse(formValues);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={props?.open}
        keepMounted
        onClose={() => props?.handleClose(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <CustomModalTitle>
          <CustomTitle>{props?.selected}</CustomTitle>
          <Button
            onClick={() => props.handleClose(false)}
            sx={{ minWidth: 'fit-content' }}
          >
            X
          </Button>
        </CustomModalTitle>
        <DialogContent>
          <CustomForm
            fields={formFields}
            handleSkip={handleSkip}
            onSubmit={handleCountinue}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
ModalForm.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.string,
  handleClose: PropTypes.func,
  handleResponse: PropTypes.func,
};

export default ModalForm;
