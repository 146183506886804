import React, { useState } from 'react';
import {
  Grid,
  Typography,
  // Checkbox,
  Dialog,
  Button,
  DialogContent,
  // DialogActions,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  CustomCopyBox,
  CustomModalButton,
  CustomModalTitle,
  CustomPaper,
  CustomTitle,
} from '../../../Utils/StyleComponents';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TerminateProcessForm from './TerminateProcessForm';

const ShowRowData = (props) => {
  const [copyMessage, SetCopyMessage] = React.useState('copy');
  const handleCopy = () => {
    const jsonData = JSON.stringify(props.rowData, null, 2); // Pretty print JSON
    navigator.clipboard
      .writeText(jsonData)
      .then(() => {
        SetCopyMessage('copied!');
        setTimeout(() => {
          SetCopyMessage('copy');
        }, 3000); // Clear the message after 3 seconds
      })
      .catch((err) => {
        console.error('Failed to copy JSON data to clipboard', err);
      });
  };
  return (
    <Box>
      <Box>
        <KeyboardBackspaceIcon onClick={() => props.back(null)} />
      </Box>
      <CustomPaper elevation={3}>
        <CustomCopyBox>
          <Button
            sx={{
              minWidth: 'fit-content',
              padding: '0px !important',
              width: 'fit-content',
              alignSelf: 'center',
            }}
            onClick={handleCopy}
          >
            <ContentCopyTwoToneIcon />
          </Button>
          <p style={{ textAlign: 'center', width: '55px', margin: '0' }}>
            {copyMessage}
          </p>
        </CustomCopyBox>
        {props?.rowData &&
          Object.keys(props?.rowData).map((key, index) => (
            <Typography variant="subtitle1" key={index}>
              <strong>{key}:</strong> {props?.rowData[key]}
            </Typography>
          ))}
      </CustomPaper>
    </Box>
  );
};

ShowRowData.propTypes = {
  rowData: PropTypes.any,
  back: PropTypes.func,
};

const TerminateProcess = (props) => {
  // eslint-disable-next-line no-unused-vars
  const handleCountinue = (formValues) => {
    props.handleResponse(formValues);
  };

  return (
    <Box>
      <Box>
        <KeyboardBackspaceIcon onClick={() => props.back(null)} />
      </Box>
      <CustomPaper elevation={3}>
        <TerminateProcessForm
          onSubmit={handleCountinue}
          filePath={props?.rowData?.processFileHashSha1}
        />
      </CustomPaper>
    </Box>
  );
};

TerminateProcess.propTypes = {
  rowData: PropTypes.any,
  back: PropTypes.func,
  handleResponse: PropTypes.func,
};

const EndProcessTable = (props) => {
  // const [selectedFields, setSelectedFields] = useState({});

  // const [perPage, setPerPage] = useState(5);
  // const [pages, setPages] = useState({
  //   current: 1,
  //   prev: undefined,
  //   min: 0,
  //   max: perPage,
  //   next: Math.ceil(props?.modalData?.length / perPage) > 1 ? 1 : undefined,
  //   count: Math.ceil(props?.modalData?.length / perPage),
  // });
  const [selectedRow, setSelectedRow] = useState(null);
  const [terminateProcess, setTerminateProcess] = useState(null);

  // const handleHeadCheckbox = (event) => {
  //   const isChecked = event.target.checked;

  //   const newSelectedFields = {};
  //   props?.modalData?.forEach((row) => {
  //     newSelectedFields[row.uuid] = isChecked;
  //   });

  //   setSelectedFields(newSelectedFields);
  // };

  // const handleClick = (event, rowId) => {
  //   setSelectedFields((prev) => ({
  //     ...prev,
  //     [rowId]: event.target.checked,
  //   }));
  // };

  const handleViewData = (row) => {
    setSelectedRow(row);
  };

  // const handleCancil = () => {
  //   setSelectedRow(null);
  //   props?.handleClose(false);
  // };

  const handleTerminate = (row) => {
    setTerminateProcess(row);
  };

  const handleTerminateProcess = (formData) => {
    setSelectedRow(null);
    setTerminateProcess(null);
    props?.handleResponse(formData);
  };

  // const handlePrev = () => {
  //   let currentPage = pages?.current;
  //   if (currentPage !== 1 && currentPage <= pages?.count) {
  //     let prev_page = currentPage - 2 > 0 ? currentPage - 2 : undefined;
  //     let next_page = currentPage <= pages.count ? currentPage : undefined;
  //     setPages((prevs) => ({
  //       ...prevs,
  //       prev: prev_page,
  //       next: next_page,
  //       current: currentPage - 1,
  //       min: (currentPage - 1) * perPage - perPage,
  //       max: (currentPage - 1) * perPage,
  //     }));
  //   } else {
  //     setPages((prev) => ({
  //       ...prev,
  //       prev: undefined,
  //     }));
  //   }
  // };
  // const handleNext = () => {
  //   let currentPage = pages?.current;
  //   if (currentPage !== 0 && currentPage <= pages?.count) {
  //     let prev_page = currentPage > 0 ? currentPage : undefined;
  //     let next_page =
  //       currentPage + 2 <= pages.count ? currentPage - 2 : undefined;
  //     setPages((prev) => ({
  //       ...prev,
  //       prev: prev_page,
  //       next: next_page,
  //       current: currentPage + 1,
  //       min: (currentPage + 1) * perPage - perPage,
  //       max: (currentPage + 1) * perPage,
  //     }));
  //   } else {
  //     setPages((prev) => ({
  //       ...prev,
  //       next: undefined,
  //     }));
  //   }
  // };

  const render = () => {
    if (selectedRow) {
      return <ShowRowData rowData={selectedRow} back={setSelectedRow} />;
    } else if (terminateProcess) {
      return (
        <TerminateProcess
          rowData={terminateProcess}
          back={setTerminateProcess}
          handleResponse={handleTerminateProcess}
        />
      );
    } else {
      return (
        <Grid container spacing={2} sx={{ border: '1px solid #ddd' }}>
          {/* Table Header */}
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: '2px solid #000', padding: '10px' }}
          >
            {/* <Grid item xs={2}>
            <Checkbox
              checked={
                Object.keys(selectedFields).filter(
                  (ele) => selectedFields[ele]
                ).length === props?.modalData?.length
              }
              indeterminate={
                Object.keys(selectedFields).some(
                  (ele) => selectedFields[ele]
                ) &&
                Object.keys(selectedFields).filter(
                  (ele) => selectedFields[ele]
                ).length < props?.modalData?.length
              }
              onChange={handleHeadCheckbox}
            />
          </Grid> */}
            <Grid item xs={3}>
              <Typography variant="h6">Process User</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Process Cmd</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">View Data</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Terminate</Typography>
            </Grid>
          </Grid>

          {/* Table Rows */}
          {props?.modalData &&
            props?.modalData?.map((row) => (
              <Grid
                container
                item
                xs={12}
                key={row.uuid}
                style={{ borderBottom: '1px solid #ddd', padding: '10px' }}
              >
                {/* <Grid item xs={2}>
              <Checkbox
                checked={selectedFields?.[row.uuid] || false}
                onChange={(event) => handleClick(event, row.uuid)}
              />
            </Grid> */}
                <Grid item xs={3}>
                  <Typography>{row.processUser}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{row.processCmd.slice(0, 13)}...</Typography>
                </Grid>
                <Grid item xs={3}>
                  <CustomModalButton
                    onClick={() => handleViewData(row)}
                    sx={{ minWidth: '80%' }}
                  >
                    View
                  </CustomModalButton>
                </Grid>
                <Grid item xs={3}>
                  <CustomModalButton
                    onClick={() => handleTerminate(row)}
                    sx={{ minWidth: '80%' }}
                  >
                    Terminate
                  </CustomModalButton>
                </Grid>
              </Grid>
            ))}
          {/* <Grid
          container
          item
          xs={12}
          style={{ borderBottom: '2px solid #000', padding: '10px' }}
        >
          <Grid item xs={4}>
            <CustomModalButton
              disabled={pages?.prev === undefined}
              onClick={handlePrev}
            >
              Prev
            </CustomModalButton>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign={'center'}>{pages?.current}</Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomModalButton
              disabled={pages?.next === undefined}
              onClick={handleNext}
            >
              Next
            </CustomModalButton>
          </Grid>
        </Grid> */}
        </Grid>
      );
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props?.open}
      keepMounted
      onClose={() => props?.handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <CustomModalTitle>
        <CustomTitle>{'End Process Data'}</CustomTitle>
        <Button
          onClick={() => props.handleClose(false)}
          sx={{ minWidth: 'fit-content' }}
        >
          X
        </Button>
      </CustomModalTitle>
      <DialogContent style={{ margin: '16px' }}>
        {props?.modalData?.length === 0 ? <>No Process Data</> : render()}
      </DialogContent>
      {/* {!selectedRow && (
        <DialogActions
          sx={{
            padding: '0px 24px 15px 24px',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <CustomModalButton onClick={handleCancil}>
                  Cancil
                </CustomModalButton>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomModalButton
                  onClick={handleTerminate}
                  disabled={props?.modalData?.length === 0}
                >
                  Terminate
                </CustomModalButton>
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      )} */}
    </Dialog>
  );
};

EndProcessTable.propTypes = {
  open: PropTypes.bool,
  modalData: PropTypes.any,
  handleClose: PropTypes.func,
  handleResponse: PropTypes.func,
};

export default EndProcessTable;
