import React from 'react';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'react-redux';
const CustomBreadCrumps = () => {
  const location = useLocation();
  const { Client, AppId } = location.state || {};
  const ClientData = useSelector((state) =>
    state?.user?.clientInfo?.find((ele) => ele.title === Client)
  );
  const [breadcrumbs, setBreadcrumbs] = React.useState([
    {
      title: 'Clients',
      path: '/',
      icon: <PeopleIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ]);

  React.useEffect(() => {
    document.title = 'Altel Home';
    if (Client) {
      setBreadcrumbs((prev) => [
        ...prev,
        {
          title: ClientData?.title,
          path: `/Client/AppList`,
          state: {
            Client: ClientData?.title,
          },
          icon: <AppsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
        },
      ]);
    }
    if (AppId) {
      const AppName = ClientData?.AppList.find(
        (ele) => ele.id === parseInt(AppId)
      );
      setBreadcrumbs((prev) => [
        ...prev,
        {
          title: AppName?.title,
          path: `/Client/App`,
          state: {
            Client: Client,
            AppId: AppId,
          },
          icon: <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
        },
      ]);
    }
  }, []);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        color: '#fff',
      }}
    >
      {breadcrumbs?.map((ele) => {
        return (
          <Link
            component={RouterLink}
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to={ele.path}
            state={ele.state}
            key={ele.title}
          >
            {ele?.icon}
            {ele.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadCrumps;
