/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import {
  useLazyUnacemSearchDashboardQuery,
  useUnacemDashboardQuery,
  useUnacemDownloadMutation,
} from '../Services/UnacemApi';
import { useSnackbar } from 'notistack';

function createData(id, filename, createdtime, expirationtime, downloaded) {
  return { id, filename, createdtime, expirationtime, downloaded };
}

const DashBoard = () => {
  const { data: initialDashboardData, refetch } = useUnacemDashboardQuery();
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [downloadXLSX] = useUnacemDownloadMutation();

  const [filename, setFilename] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  // Use the lazy query hook
  const [triggerSearch, { data: searchResults, error }] =
    useLazyUnacemSearchDashboardQuery();

  // Function to handle the Search button click
  const handleSearch = () => {
    const startDate = fromDate ? fromDate.format('YYYY-MM-DD') : '';
    const endDate = toDate ? toDate.format('YYYY-MM-DD') : '';
    triggerSearch({ filename, start_date: startDate, end_date: endDate });
  };

  function handleEnter (event) {
    if (event.key === "Enter") {
      handleSearch();
    }
    if (event.key === "Backspace") {
      const inputField = event.target;
      if (inputField.value.trim() === '') {
        handleSearch();
      }
    }
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (initialDashboardData) {
      const formattedRows = initialDashboardData.map((item) =>
        createData(item.id, item.filename, item.start_date, item.end_date)
      );
      setRows(formattedRows);
    }
  }, [initialDashboardData]);

  useEffect(() => {
    if (searchResults) {
      const formattedRows = searchResults.map((item) =>
        createData(item.id, item.filename, item.start_date, item.end_date)
      );
      setRows(formattedRows);
    }
  }, [searchResults]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDownload = async (filename, file_id) => {
    try {
      const response = await downloadXLSX({
        file_name: filename,
        file_id: file_id,
      });
      if (
        response?.error?.originalStatus >= 400 &&
        response?.error?.originalStatus < 500
      ) {
        enqueueSnackbar('File not found', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (response?.error?.originalStatus >= 500) {
        enqueueSnackbar('Internal Server Error', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        enqueueSnackbar('Download initiated successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message || 'An error occurred', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };

  const columns = [
    // { id: 'fileid', label: 'id', minWidth: 170 },
    { id: 'filename', label: 'File Name', minWidth: 170 },
    { id: 'createdtime', label: 'Created Time', minWidth: 100 },
    {
      id: 'expirationtime',
      label: 'Expiration Time',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'downloaded',
      label: 'Downloads',
      minWidth: 170,
      align: 'right',
    },
  ];

  const datePickerStyles = {
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'black',
    },
    '& .MuiPickersDay-root': {
      color: 'white',
    },
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centers both sections in the window
          gap: '20px',
          width: '100%',
          color: '#fff',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns content to the left
            width: '100%',
            maxWidth: '600px', // Set a max-width for better alignment
            gap: '10px',
          }}
        >
          <div style={{ fontSize: '20px' }}>File Name</div>
          <TextField
            placeholder="Filename"
            id="outlined-controlled"
            value={filename}
            onChange={(e) => setFilename(e.target.value)} // Extracting the value from the event
            sx={{
              flexGrow: 1, // Makes the input take available space
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#fff',
                },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
              },
              '& .MuiInputBase-input::placeholder': {
                opacity: 1,
              },
            }}
            onKeyDown={(e) => handleEnter(e)}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns content to the left
            width: '100%',
            maxWidth: '600px', // Set a max-width for better alignment
            gap: '10px',
          }}
        >
          <div style={{ fontSize: '20px' }}>Range Time</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                label="From"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                shouldDisableDate={(date) => date.isAfter(dayjs())}
                sx={datePickerStyles}
              />
              <DatePicker
                label="To"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                shouldDisableDate={(date) => date.isAfter(dayjs())}
                sx={datePickerStyles}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <Button variant="contained" onClick={handleSearch}>
          <SearchIcon />
          Search
        </Button>
        {error && <p>Error: {error.message}</p>}
      </div>

      <Paper
        sx={{
          maxWidth: 1000,
          overflow: 'hidden',
          color: '#07166cb8',
          margin: '2rem auto',
          alignContent: 'center',
        }}
      >
        <TableContainer
          sx={{ maxHeight: 440, background: '#07166cb8', border: 'none' }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ maxHeight: 440, background: '#07166cb8', border: 'none' }}
          >
            <TableHead sx={{ background: '#07166cb8', color: '#07166cb8' }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      background: '#07166cb8',
                      color: '#fff',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.filename}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ color: '#fff' }}
                        >
                          {column.id === 'downloaded' ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleDownload(row.filename, row.id)
                              }
                            >
                              Download
                            </Button>
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '&.MuiTablePagination-root': {
              background: '#07166cb8',
              color: '#fff',
            },
          }}
        />
      </Paper>
    </>
  );
};

export default DashBoard;
