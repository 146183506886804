import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { ItemButton, TitleBox } from '../Utils/StyleComponents';
import CustomBreadCrumps from './Components/CustomBreadCrumps';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import { updateNotification } from '../Store/clientSlice';

const HomePage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  // const [socket, setSocket] = React.useState(null);
  // const user = useSelector((state) => state?.user?.userInfo);
  const ClientData = useSelector((state) => state?.user?.clientInfo);

  const mobileView = useMediaQuery('(max-width: 600px)');

  // React.useEffect(() => {
  //   const socket = new WebSocket(
  //     `ws://127.0.0.1:8000/ws?useremail=${user?.email}`
  //   );

  //   socket.onopen = () => {
  //     console.log('WebSocket is connected');
  //   };

  //   socket.onmessage = (event) => {
  //     console.log(event);
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket is closed');
  //   };

  //   socket.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };

  //   setSocket(socket);

  //   return () => socket.close(); // Clean up on unmount
  // }, []);

  const handleClick = (ele) => {
    if (ele?.AppList && ele?.AppList?.length > 0) {
      navigate('/Client/AppList', {
        state: { Client: ele?.title },
      });
    } else {
      enqueueSnackbar(
        'You do not have access. Please contact the administrator.',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        }
      );
    }
  };
  return (
    <>
      <CustomBreadCrumps />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          sx={{
            width: mobileView ? '100%' : '74% !important',
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TitleBox>Clients</TitleBox>
          </Grid>
          {ClientData?.length === 0 && (
            <Box sx={{ color: 'white' }}>No Clients!</Box>
          )}
          {ClientData?.map((ele, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <ItemButton onClick={() => handleClick(ele)}>
                {ele.title}
              </ItemButton>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default HomePage;
