import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { useDispatch } from 'react-redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { TrendMicroApi } from '../Services/TrendMicroApi';
import clientReducer from './clientSlice';
import userReducer from './userSlice';
import { userApi } from '../Services/UserApi';
import { ClientApi } from '../Services/ClientApi';
import { ProfileApi } from '../Services/ProfileApi';
import { UnacemApi } from '../Services/UnacemApi';

const rootReducers = combineReducers({
  user: userReducer,
  client: clientReducer,
  [TrendMicroApi.reducerPath]: TrendMicroApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [ClientApi.reducerPath]: ClientApi.reducer,
  [ProfileApi.reducerPath]: ProfileApi.reducer,
  [UnacemApi.reducerPath]: UnacemApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['user-info'],
  transforms: [
    encryptTransform({
      secretKey: 'altel-data-encryption-key',
      onError: function () {
        console.error('unable to encrypt redux store :(');
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(TrendMicroApi.middleware)
      .concat(ClientApi.middleware)
      .concat(userApi.middleware)
      .concat(ProfileApi.middleware)
      .concat(UnacemApi.middleware),
  devTools: true,
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
export const useAppDispatch = () => useDispatch(AppDispatch);
export const persistor = persistStore(store);
