import React, { useMemo } from 'react';
import { CustomButton, TitleBox } from '../Utils/StyleComponents';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Notification from './Notification';
import CustomBreadCrumps from './Components/CustomBreadCrumps';
import {
  useCollectFileMutation,
  useEndProcessListMutation,
  useIsolateMutation,
  useLazyGetAdminPermissionQuery,
  useRestoreMutation,
  useStartMalwareScanMutation,
  useTerminateProcessMutation,
} from '../Services/TrendMicroApi';
import './index.scss';
import { useSnackbar } from 'notistack';
import HostDetailModal from './HostDetailModal';
import { updateNotification } from '../Store/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import EndProcessTable from './AppPages/TrendMicro/EndProcessTable';
import ModalForm from './AppPages/TrendMicro/ModalForm';
// import { EndProcessData } from '../Utils/Constants';

const CustomListItem = (props) => {
  const Disabled = props?.access !== undefined ? !props?.access : true;
  return (
    <ListItem
      sx={{
        cursor: Disabled || props?.loading ? 'not-allowed' : 'pointer',
        width: 'fit-content',
      }}
    >
      <CustomButton
        disabled={Disabled || props?.loading}
        onClick={() => props?.handleClick(props?.value)}
      >
        {props?.title}
        {/* {IsolateLoading && <CustomCircularProgress />} */}
      </CustomButton>
    </ListItem>
  );
};
CustomListItem.propTypes = {
  loading: PropTypes.bool,
  access: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  handleClick: PropTypes.func,
};

const TrendMicroResponse = () => {
  const location = useLocation();
  const { Client, AppId } = location.state || {};
  const navigate = useNavigate();
  const App = useSelector((state) =>
    state?.user?.clientInfo
      ?.find((ele) => ele.title === Client)
      ?.AppList?.find((ele) => ele.id === AppId)
  );

  const mobileView = useMediaQuery('(max-width: 600px)');
  const [Isolate, { isLoading: IsolateLoading }] = useIsolateMutation();
  const [Restore, { isLoading: RestoreLoading }] = useRestoreMutation();
  const [triggerPermission, { data: Permissions }] =
    useLazyGetAdminPermissionQuery();
  const dispatch = useDispatch();
  const [CollectFile, { isLoading: CollectFileLoading }] =
    useCollectFileMutation();
  const [TerminateProcess, { isLoading: TerminateProcessLoading }] =
    useTerminateProcessMutation();
  const [StartMalwareScan, { isLoading: StartMalwareScanLoading }] =
    useStartMalwareScanMutation();
  const [EndProcessList, { isLoading: EndProcessListLoading }] =
    useEndProcessListMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [hostDetail, setHostDetail] = React.useState('');
  const [hostData, setHostData] = React.useState();
  const [hostDataResponse, setHostDataResponse] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalFromOpen, setModalFromOpen] = React.useState(false);
  const [endProcessTableModal, setEndProcessTableModal] = React.useState(false);
  const [endProcessData, setEndProcessData] = React.useState([]);
  const [selectedButton, setSelectedButton] = React.useState('');
  // const Permissions = ['ISOLATION', 'RESTART', 'COLLECT', 'MALWARE_SCAN'];

  React.useEffect(() => {
    triggerPermission({ app: App?.title });
  }, []);

  React.useEffect(() => {
    if (!Client) {
      navigate('/');
    }
    if (!AppId) {
      navigate('/Client/AppList', {
        state: { Client: Client },
      });
    }
  }, [Client, AppId]);

  React.useEffect(() => {
    setIsLoading(
      IsolateLoading ||
        RestoreLoading ||
        CollectFileLoading ||
        EndProcessListLoading ||
        StartMalwareScanLoading ||
        TerminateProcessLoading
    );
  }, [
    IsolateLoading,
    RestoreLoading,
    CollectFileLoading,
    EndProcessListLoading,
    StartMalwareScanLoading,
    TerminateProcessLoading,
  ]);
  const handleResponse = async (formdata = {}) => {
    if (!hostDetail) {
      enqueueSnackbar('Please Enter Valid HostName/IP', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      return;
    }

    const body = {
      ip_address: hostDetail,
      app: App?.title,
      client: Client,
      action: selectedButton,
      ...formdata,
    };

    const actionMap = {
      ISOLATION: Isolate,
      RESTART: Restore,
      COLLECT: CollectFile,
      END_PROCESS: EndProcessList,
      MALWARE_SCAN: StartMalwareScan,
    };

    const actionFunction = actionMap[selectedButton];
    if (!actionFunction) return;

    try {
      const responseData = await actionFunction(body);
      if (responseData?.data) {
        if (selectedButton === 'MALWARE_SCAN') {
          enqueueSnackbar(responseData.data.detail, {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        } else if (selectedButton === 'END_PROCESS') {
          setEndProcessData(responseData?.data?.response?.items);
          setEndProcessTableModal(true);
        } else {
          const { response, link_data } = responseData.data;
          if (Object.keys(link_data).length > 0) {
            setHostDataResponse(response?.[0]?.body);
            setHostData(link_data?.data_of_operation_location);
            dispatch(updateNotification(link_data?.data_of_operation_location));
            setShowModal(true);
          } else {
            enqueueSnackbar(
              `${response?.[0]?.status}:${response?.[0]?.body?.error?.message}`,
              {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
              }
            );
          }
        }
      } else {
        handleResponseError(responseData);
      }
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };

  const handleResponseError = (responseData) => {
    const { error } = responseData;
    if (!error) return;

    const { status, data } = error;
    let message = '';

    if (status === 404 || status === 400) {
      message = data.detail?.error?.message || data.detail;
    } else if (status === 401) {
      message = `${data.detail}, Try Again!`;
      triggerPermission({ app: App?.title });
    } else if (error?.error) {
      message = error.error;
    }

    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  };

  const handleClick = (ele) => {
    if (hostDetail) {
      setSelectedButton(ele);
      setModalFromOpen(true);
    } else {
      enqueueSnackbar('Please Enter Valid HostName/IP', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };

  const handleTerminate = async (formData) => {
    const body = {
      ip_address: hostDetail,
      app: App?.title,
      client: Client,
      action: event.target.innerText,
      ...formData,
    };
    setEndProcessTableModal(false);
    let responseData = await TerminateProcess(body);
    if (responseData?.data) {
      const { response, link_data } = responseData.data;
      if (Object.keys(link_data).length > 0) {
        setHostDataResponse(response?.[0]?.body);
        setHostData(link_data?.data_of_operation_location);
        dispatch(updateNotification(link_data?.data_of_operation_location));
        setShowModal(true);
      } else {
        enqueueSnackbar(
          `${response?.[0]?.status}:${response?.[0]?.body?.error?.message}`,
          {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          }
        );
      }
    } else {
      handleResponseError(responseData);
    }
  };

  const CustomListItems = useMemo(
    () => [
      {
        title: 'Isolation',
        value: 'ISOLATION',
        loading: IsolateLoading,
        access: Permissions?.includes('ISOLATION'),
      },
      {
        title: 'Restart',
        value: 'RESTART',
        loading: RestoreLoading,
        access: Permissions?.includes('RESTART'),
      },
      {
        title: 'Collect',
        value: 'COLLECT',
        loading: CollectFileLoading,
        access: Permissions?.includes('COLLECT'),
      },
      {
        title: 'End Process',
        value: 'END_PROCESS',
        loading: EndProcessListLoading,
        access: Permissions?.includes('END_PROCESS'),
      },
      {
        title: 'Malware Scan',
        value: 'MALWARE_SCAN',
        loading: StartMalwareScanLoading,
        access: Permissions?.includes('MALWARE_SCAN'),
      },
    ],
    [
      Permissions,
      IsolateLoading,
      RestoreLoading,
      CollectFileLoading,
      EndProcessListLoading,
      StartMalwareScanLoading,
    ]
  );
  return (
    <>
      <CustomBreadCrumps />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          sx={{
            width: mobileView ? '100%' : '74% !important',
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TitleBox>Response</TitleBox>
          </Grid>
          <Grid item xs={11} sm={11} md={11}>
            <Grid item xs={12} sm={12} md={12}>
              <div
                style={{
                  gap: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#fff',
                }}
              >
                <div style={{ margin: 'auto 0', fontSize: '20px' }}>
                  HOSTNAME/IP
                </div>
                <TextField
                  placeholder="Enter HOSTNAME/IP"
                  id="outlined-controlled"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#fff', // Default color
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff', // On hover
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      opacity: 1,
                    },
                  }}
                  InputProps={{
                    autoComplete: 'off', // Disables autocomplete
                  }}
                  value={hostDetail}
                  onChange={(event) => {
                    setHostDetail(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <List component="div" disablePadding>
                {CustomListItems.map((item) => (
                  <CustomListItem
                    key={item.value}
                    loading={item.loading}
                    access={item.access}
                    value={item.value}
                    title={item.title}
                    handleClick={handleClick}
                  />
                ))}
              </List>
            </Grid>
            {modalFromOpen && (
              <ModalForm
                open={modalFromOpen}
                selected={selectedButton}
                handleClose={setModalFromOpen}
                handleResponse={handleResponse}
              />
            )}
            {endProcessTableModal && (
              <EndProcessTable
                open={endProcessTableModal}
                handleClose={setEndProcessTableModal}
                modalData={endProcessData}
                handleResponse={handleTerminate}
              />
            )}
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Notification />
            <HostDetailModal
              show={showModal}
              setShowModal={setShowModal}
              data={hostData}
              hostDataResponse={hostDataResponse}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default TrendMicroResponse;
