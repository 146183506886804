import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomForm.scss';
import { CustomModalButton } from '../../../Utils/StyleComponents';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const CustomForm = ({ fields, handleSkip, onSubmit }) => {
  const validationSchema = Yup.object(
    fields.reduce((schema, field) => {
      if (field.required) {
        schema[field.name] = Yup.string()
          .test(
            'is-only-spaces',
            'Invalid input: Only spaces are not allowed',
            (value) => {
              // Check if the trimmed value consists only of spaces
              return value.trim() !== '';
            }
          )
          .required('This field is required');
      }
      return schema;
    }, {})
  );

  const initialValues = fields.reduce((values, field) => {
    values[field.name] = '';
    return values;
  }, {});

  // Use Formik hook
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values, { validateForm, setErrors, resetForm }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          onSubmit(values);
        } else {
          setErrors(errors);
        }
      });
      resetForm();
    },
  });

  const handleCancel = () => {
    formik.handleReset();
    handleSkip();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {fields.map((field) => (
        <Box key={field?.name} mb={3}>
          <Typography variant="body1" component="label" htmlFor={field?.name}>
            {field?.label}{' '}
            {field?.required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <TextField
            id={field?.name}
            name={field?.name}
            placeholder={field?.placeholder}
            sx={{
              width: '100%',
              color: 'black',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#000000', // Default color
                },
                '&:hover fieldset': {
                  borderColor: '#000000', // On hover
                },
              },
              '& .MuiInputBase-input::placeholder': {
                opacity: 1,
              },
            }}
            InputProps={{
              autoComplete: 'off', // Disables autocomplete
            }}
            value={formik.values[field?.name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors[field?.name])}
          />
          {Boolean(formik.errors[field?.name]) && (
            <Typography
              style={{
                gap: '6px',
                color: 'red',
                display: 'flex',
                fontSize: '12px',
              }}
            >
              <ReportProblemIcon
                sx={{
                  width: '15px',
                  height: '15px',
                }}
              />{' '}
              {formik.errors[field?.name]}
            </Typography>
          )}
        </Box>
      ))}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <CustomModalButton type="button" onClick={handleCancel}>
              Cancel
            </CustomModalButton>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomModalButton type="submit">Continue</CustomModalButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
CustomForm.propTypes = {
  fields: PropTypes.any,
  handleSkip: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CustomForm;
