import { Route, Routes } from 'react-router-dom';
import { useCallback, lazy, Suspense } from 'react';
import { CircularProgress, Box } from '@mui/material';
import HomePage from '../Pages/HomePage';
import AppList from '../Pages/AppList';
// import TrendMicroResponse from '../Pages/TrendMicroResponse';
import LoginPage from '../Pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicLayout from '../Pages/Layout/PublicLayout';
import PageNotFound from '../Pages/StaticPages/PageNotFound';
// import { useSelector } from 'react-redux';
import AppResponse from '../Pages/AppResponse';
import ProfilePage from '../Pages/AppPages/Profile/ProfilePage';
import { ForgetPassword } from '../Pages/AppPages/ForgetPassword/ForgetPassword';
import ResetPassword from '../Pages/AppPages/ForgetPassword/ResetPassword';
import DashBoard from '../Pages/Dashboard';
const MainLayout = lazy(() => import('../Pages/Layout/MainLayout'));

const PageRoutes = () => {
  const AuthenticationContainer = useCallback((children) => {
    return (
      <PrivateRoute>
        <MainLayout>{children}</MainLayout>
      </PrivateRoute>
    );
  }, []);
  const PublicRoute = useCallback((children) => {
    return <PublicLayout>{children}</PublicLayout>;
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              zIndex: 1,
              // backgroundColor: (theme) => theme.palette.background?.default,
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <Routes>
          <Route path="/login" element={PublicRoute(<LoginPage />)} />
          <Route path="/forget" element={PublicRoute(<ForgetPassword />)} />
          <Route path="/reset" element={PublicRoute(<ResetPassword />)} />
          <Route path="/" element={AuthenticationContainer(<HomePage />)} />
          <Route
            path="/dashboard"
            element={AuthenticationContainer(<DashBoard />)}
          />
          <Route
            path="/profile"
            element={AuthenticationContainer(<ProfilePage />)}
          />
          <Route
            path="/Client/AppList"
            element={AuthenticationContainer(<AppList />)}
          />
          {/* <Route
            path="/Client/App/TrendMicro"
            element={AuthenticationContainer(<TrendMicroResponse />)}
          /> */}
          <Route
            path="/Client/App"
            element={AuthenticationContainer(<AppResponse />)}
          />
          <Route path="/*" element={PublicRoute(<PageNotFound />)} />
          <Route path="/notfound" element={PublicRoute(<PageNotFound />)} />
        </Routes>
      </Suspense>
    </>
  );
};

export default PageRoutes;
