import { Box, Card, Grid, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import SecurityForm from './SecurityForm';
import ProfileForm from './ProfileForm';

const ProfilePage = () => {
  const [value, setValue] = useState('profile');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '8%',
        }}
      >
        <Grid
          container
          sx={{
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item xs={12} sm={5} md={6} lg={6} xl={6}>
              <Card
                sx={{
                  background: '#07166cb8',
                  color: '#fff !important',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: '#2496D8',
                      },
                    }}
                  >
                    <Tab
                      value="profile"
                      label="Profile"
                      sx={{
                        color: '#fff',
                        '&.Mui-selected': {
                          color: '#fff',
                        },
                      }}
                    />
                    <Tab
                      value="security"
                      label="Security"
                      sx={{
                        color: '#fff',
                        '&.Mui-selected': {
                          color: '#fff',
                        },
                      }}
                    />
                  </Tabs>
                </Box>
                {value === 'profile' ? <ProfileForm /> : <SecurityForm />}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProfilePage;
