import { Box, Card, CardContent, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { TitleBox } from '../../Utils/StyleComponents';
const PageNotFound = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '8%',
        }}
      >
        <Grid
          container
          sx={{
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
              <Card
                sx={{
                  background: 'transparent',
                  color: '#fff !important',
                }}
              >
                <CardContent>
                  <TitleBox>
                    <CancelIcon sx={{ margin: '-3px' }} /> Page Not Found
                  </TitleBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PageNotFound;
