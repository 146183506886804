import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { updateUserTokens, updateUserDetails } from '../Store/userSlice';
// import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, api) => {
    headers.set(
      'Authorization',
      `Bearer ${api.getState().user?.tokens?.access}`
    );
    return headers;
  },
});

const AuthenticatedBaseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  const userData = api.getState().user?.userInfo;
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: `/token/refresh/?token=${userData.refresh_token}`,
            method: 'GET',
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          api.dispatch(updateUserTokens(refreshResult.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(updateUserDetails(null));
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default AuthenticatedBaseQuery;
