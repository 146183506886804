import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import { Line, LinesBox } from '../../Utils/StyleComponents';
// import BgImage from '../../Assets/bg.jpg';
import Bg2 from '../../Assets/bg2.jpeg';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const PublicLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Main
        style={{
          position: 'relative',
          minHeight: '100vh',
          zIndex: 1,
          backgroundImage: `url(${Bg2}`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <LinesBox>
          <Line />
          <Line />
          <Line />
        </LinesBox>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PublicLayout;
