// PrivateRoute.jsx
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state?.user?.userInfo?.email);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return user ? children : null;
};

PrivateRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PrivateRoute;
