import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  CustomLoginButton,
  CustomInputAdornment,
  CustomTextField,
  TitleBox,
  //   CustomTextLabel,
} from '../Utils/StyleComponents';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useUserLoginMutation } from '../Services/UserApi';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo, updateUserTokens } from '../Store/userSlice';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileView = useMediaQuery('(max-width: 600px)');
  const user = useSelector((state) => state?.user?.userInfo?.email);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginApi, { isLoading }] = useUserLoginMutation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { enqueueSnackbar } = useSnackbar();
  //   const mobileView = useMediaQuery('(max-width: 600px)');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
    password: Yup.string().required('This field is required'),
  });
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: (values, { validateForm, setErrors, resetForm }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleLogin(values, resetForm);
        } else {
          setErrors(errors);
        }
      });
    },
  });

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleLogin = async (formValues, resetForm) => {
    const response = await loginApi(formValues);
    if (response?.error) {
      resetForm();
      if (response?.error?.error) {
        enqueueSnackbar(response?.error?.error, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        enqueueSnackbar(response?.error?.data?.detail, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
    if (response?.data) {
      dispatch(updateUserInfo(response?.data));
      dispatch(updateUserTokens(response?.data));
      navigate('/');
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '8%',
        }}
      >
        <Grid
          container
          sx={{
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
              <Card
                sx={{
                  background: '#07166cb8',
                  color: '#fff !important',
                  borderRadius: mobileView ? '0% !important' : '6% !important',
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <CardContent>
                    <TitleBox>Altel Admin</TitleBox>
                    <Box>
                      {/* <CustomTextLabel>Email</CustomTextLabel> */}
                      <CustomTextField
                        autoComplete="off"
                        placeholder="Enter Email"
                        name="email"
                        type="email"
                        InputProps={{
                          startAdornment: (
                            <CustomInputAdornment position="start">
                              <EmailIcon />
                            </CustomInputAdornment>
                          ),
                        }}
                        value={formik.values.email || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                      />
                    </Box>
                    <Box>
                      {/* <CustomTextLabel>Password</CustomTextLabel> */}
                      <CustomTextField
                        autoComplete="off"
                        placeholder="Enter Password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <CustomInputAdornment position="start">
                              <PasswordIcon />
                            </CustomInputAdornment>
                          ),
                          endAdornment: (
                            <CustomInputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                sx={{
                                  color: '#fff',
                                }}
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </CustomInputAdornment>
                          ),
                        }}
                        value={formik.values.password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.errors.password)}
                        helperText={formik.errors.password}
                      />
                    </Box>
                    <Typography
                      textAlign="end"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate('/forget')}
                    >
                      Forget Password ?
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <CustomLoginButton type="submit">Log in</CustomLoginButton>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoginPage;
