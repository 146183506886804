/* eslint-disable no-debugger */
import { createApi } from '@reduxjs/toolkit/query/react';
import AuthenticatedBaseQuery from './AuthenticatedQuery';

export const UnacemApi = createApi({
  reducerPath: 'UnacemApi',
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    sophus: builder.mutation({
      query: (body) => ({
        url: `/unacem/sophos-endpoints/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    vicarius: builder.mutation({
      query: (body) => ({
        url: `/unacem/vicarius-endpoints/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    inventoryControl: builder.mutation({
      query: (body) => ({
        url: `/unacem/inventory-control/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),
    trendWorkload: builder.mutation({
      query: (body) => ({
        url: `/unacem/trendmicro-workload-endpoints/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    unacemDashboard: builder.query({
      query: () => ({
        url: `/unacem/unacem-dashboard/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
    unacemDownload: builder.mutation({
      query: (data) => ({
        url: `/unacem/unacem-dashboard-download?file_id=${data.file_id}`,
        method: 'GET',
        responseHandler: async (response) => {
          if (response?.status === 200) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = data.file_name;
            link.click();

            window.URL.revokeObjectURL(url);
          } else {
            const errorText = await response.text();
            throw new Error(errorText || 'Failed to download file');
          }
          return null;
        }
      }),
    }),
    unacemSearchDashboard: builder.query({
      query: ({ filename = '', start_date = '', end_date = '' }) => {
        // Construct URL with query parameters only if they are provided
        const params = new URLSearchParams();

        if (filename) {
          params.append('filename', filename);
        }
        if (start_date) {
          params.append('start_date', start_date);
        }
        if (end_date) {
          params.append('end_date', end_date);
        }

        return {
          url: `/unacem/unacem-dashboard?${params.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useSophusMutation,
  useVicariusMutation,
  useInventoryControlMutation,
  useTrendWorkloadMutation,
  useUnacemDashboardQuery,
  useLazyUnacemDashboardQuery,
  useLazyUnacemSearchDashboardQuery,
  useUnacemDownloadMutation
} = UnacemApi;
