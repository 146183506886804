import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  InputAdornment,
  keyframes,
  TextField,
  Typography,
} from '@mui/material';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding: '20px',
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
  transition: '0.6s ease-in',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: '-15px',
    right: '-15px',
    background: '#7952b3',
    height: '220px',
    width: '25px',
    borderRadius: '32px',
    transform: 'scale(1)',
    transformOrigin: '50% 50%',
    transition: 'transform 0.25s ease-out',
  },
  '&:hover::before': {
    transitionDelay: '0.2s',
    transform: 'scale(40)',
  },
  '&:hover': {
    color: '#ffffff',
  },
  '&:disabled': {
    color: '#ffffff',
  },
}));

export const NotificationCount = styled(Box)(() => ({
  textAlign: 'center',
  width: '20px',
  border: '1px solid black',
  borderRadius: '5px',
  background: '#000000',
  color: 'white',
}));

export const ItemButton = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  position: 'relative',
  backgroundColor: '#2496D8',
  minWidth: '170px',
  textTransform: 'uppercase',
  color: '#fff',
  height: '100%',
  zIndex: 0,
  cursor: 'pointer',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    left: '50%',
    height: '100%',
    width: '0',
    background: 'transparent',
    transition: 'width .5s ease, background-color .5s ease, left .5s ease',
  },
  '&:hover::after': {
    width: '100%',
    left: 0,
    background: '#0E0129',
  },
  '&:hover': {
    boxShadow:
      '0 1px 15px rgb(17 168 241 / 64%), 0 1px 15px rgb(8 25 227 / 92%)',
    color: '#ffffff',
  },
  '&[disabled]': {
    color: '#000000' /* Change text color when disabled */,
    // backgroundColor: '#E5E5E5' /* Example disabled background color */,
    cursor: 'not-allowed',
  },
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  fontSize: '25px',
  fontWeight: '500',
  color: '#fff',
}));

const drop = keyframes`
  0% {
    top: -25vh;
  }
  50% {
    top: 100%;
  }
  100% {
    top: -25vh;
  }
`;

export const LinesBox = styled('div')(() => ({
  position: 'absolute',
  zIndex: -1,
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  margin: 'auto',
  width: '90vw',
}));

export const Line = styled('div')(() => ({
  position: 'absolute',
  width: '1px',
  height: '100%',
  zIndex: -1,
  top: 0,
  left: '50%',
  background: 'rgba(255, 255, 255, 0.1)',
  overflow: 'hidden',

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    height: '2vh',
    width: '100%',
    top: '-50%',
    left: 0,
    background: 'white',
    // background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%)',
    animation: `${drop} 7s 0s infinite`,
  },

  '&:nth-of-type(1)': {
    marginLeft: '-25%',
    '&::after': {
      animationDelay: '2s',
    },
  },

  '&:nth-of-type(3)': {
    marginLeft: '25%',
    '&::after': {
      animationDelay: '2.5s',
    },
  },
}));

export const CustomFab = styled(Fab)(() => ({
  color: '#000000',
  background: '#2A9DFF',
  borderRadius: '8px',
  padding: '10px',
  gap: '8px',
  width: 'fit-content',
  '&:hover': {
    background: '#2A9DFF',
  },
}));

export const CustomModalTitle = styled('div')(() => ({
  display: 'flex',
  padding: '0 24px',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '15px',
}));

export const CustomTitle = styled('div')(() => ({
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: '500',
  color: '#000000',
  margin: '0',
}));

export const CustomNotificationBar = styled('div')(() => ({
  display: 'flex',
  padding: '0 24px',
  justifyContent: 'space-between',
  width: '100%',
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  position: 'relative',
  backgroundColor: '#2496D8',
  minWidth: '180px',
  textTransform: 'uppercase',
  color: '#fff',
  height: '100%',
  fontWeight: '500',
  fontSize: '18px',
  zIndex: 0,
  cursor: 'pointer',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    left: '50%',
    height: '100%',
    width: '0',
    background: 'transparent',
    transition: 'width .5s ease, background-color .5s ease, left .5s ease',
  },
  '&:hover::after': {
    width: '100%',
    left: 0,
    background: '#0E0129',
  },
  '&:hover': {
    boxShadow:
      '0 1px 15px rgb(17 168 241 / 64%), 0 1px 15px rgb(8 25 227 / 92%)',
    color: '#ffffff',
  },
  '&[disabled]': {
    color: '#000000' /* Change text color when disabled */,
    // backgroundColor: '#E5E5E5' /* Example disabled background color */,
    cursor: 'not-allowed',
  },
}));

export const CustomCircularProgress = styled(CircularProgress)(() => ({
  width: '15px !important',
  height: '15px !important',
  color: '#00000 !important',
}));
export const CustomErrorBox = styled(Box)(() => ({
  background: 'red',
  color: 'white',
}));
export const CustomSuccesBox = styled(Box)(() => ({
  background: 'green',
  color: 'white',
}));
export const CustomTextField = styled(TextField)(() => ({
  marginTop: '10px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    color: '#fff !important',
    '& fieldset': {
      borderColor: '#fff', // Default color
    },
    '&:hover fieldset': {
      borderColor: '#fff', // On hover
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#fff', // Customize the placeholder color here
    opacity: 1, // Adjust opacity if needed
  },
}));
export const CustomTextLabel = styled(Typography)(() => ({
  color: 'white',
}));

export const CustomInputAdornment = styled(InputAdornment)(() => ({
  color: 'white',
}));
export const CustomLoginButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  position: 'relative',
  backgroundColor: '#2496D8',
  minWidth: '180px',
  textTransform: 'uppercase',
  color: '#fff',
  height: '100%',
  fontWeight: '500',
  fontSize: '16px',
  zIndex: 0,
  cursor: 'pointer',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    left: '50%',
    height: '100%',
    width: '0',
    background: 'transparent',
    transition: 'width .5s ease, background-color .5s ease, left .5s ease',
  },
  '&:hover::after': {
    width: '100%',
    left: 0,
    background: '#0E0129',
  },
  '&:hover': {
    boxShadow:
      '0 1px 15px rgb(17 168 241 / 64%), 0 1px 15px rgb(8 25 227 / 92%)',
    color: '#ffffff',
  },
  '&[disabled]': {
    color: '#000000' /* Change text color when disabled */,
    // backgroundColor: '#E5E5E5' /* Example disabled background color */,
    cursor: 'not-allowed',
  },
}));

export const CustomPaper = styled(Paper)(() => ({
  padding: 20,
  position: 'relative',
}));

export const CustomCopyBox = styled(Box)(() => ({
  position: 'absolute',
  top: '2%',
  right: '0',
  minWidth: 'fit-content',
  padding: '0px !important',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'end',
  // color: 'blue',
  background: 'transparent',
}));

export const CustomModalButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  position: 'relative',
  backgroundColor: '#2496D8',
  minWidth: '100%',
  lineHeight: '0',
  textTransform: 'uppercase',
  color: '#fff',
  height: '100%',
  zIndex: 0,
  cursor: 'pointer',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    left: '50%',
    height: '100%',
    width: '0',
    background: 'transparent',
    transition: 'width .5s ease, background-color .5s ease, left .5s ease',
  },
  '&:hover::after': {
    width: '100%',
    left: 0,
    background: '#0E0129',
  },
  '&:hover': {
    boxShadow:
      '0 1px 15px rgb(17 168 241 / 64%), 0 1px 15px rgb(8 25 227 / 92%)',
    color: '#ffffff',
  },
  '&[disabled]': {
    color: '#000000' /* Change text color when disabled */,
    // backgroundColor: '#E5E5E5' /* Example disabled background color */,
    cursor: 'not-allowed',
  },
}));

export const CustomLabel = styled(Typography)(() => ({
  color: 'black',
}));
