import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import TrendMicroResponse from './TrendMicroResponse';
import { useSnackbar } from 'notistack';
// import ImpervaResponse from './ImpervaResponse';

const AppResponse = () => {
  const location = useLocation();
  const { Client, AppId } = location.state || {};
  const { enqueueSnackbar } = useSnackbar();
  const App = useSelector((state) =>
    state?.user?.clientInfo
      ?.find((ele) => ele.title === Client)
      ?.AppList?.find((ele) => ele.id === AppId)
  );
  const render = () => {
    switch (App?.title) {
      case 'CER-Trend Micro':
      case 'Serviandina-Trend Micro':
      case 'Mavesa-Trend Micro':
      case 'Plasticacho-Trend Micro':
      case 'TPG-Trend Micro':
      case 'Interoc-Trend Micro':
        return <TrendMicroResponse />;
      // case 'Imperva':
      //   return <ImpervaResponse />;
      default:
        enqueueSnackbar(
          'You do not have access. Please contact the administrator.',
          {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          }
        );
        return <Navigate to="/Client/AppList" state={{ Client: Client }} />;
    }
  };
  return <>{render()}</>;
};

export default AppResponse;
