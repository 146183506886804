import { createApi } from '@reduxjs/toolkit/query/react';
import AuthenticatedBaseQuery from './AuthenticatedQuery';

export const ProfileApi = createApi({
  reducerPath: 'ProfileApi',
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    updateUserDetails: builder.mutation({
      query: (body) => ({
        url: '/admins/update/',
        method: 'PUT',
        body,
      }),
    }),
    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: '/admins/password',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useUpdateUserDetailsMutation, useUpdateUserPasswordMutation } =
  ProfileApi;
