import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ResetPasswordSchema } from '../../../Utils/ValidationSchemas';
import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  CustomInputAdornment,
  CustomLoginButton,
  CustomTextField,
  TitleBox,
} from '../../../Utils/StyleComponents';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordIcon from '@mui/icons-material/Password';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useResetPasswordMutation,
  useVerfiyOtpMutation,
} from '../../../Services/UserApi';
import { useSnackbar } from 'notistack';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { otp, email } = location.state || {};
  const mobileView = useMediaQuery('(max-width: 600px)');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verifyOtp, { isLoading: verfiyOtpLoading }] = useVerfiyOtpMutation();
  const [changePassword, { isLoading: changePasswordLoading }] =
    useResetPasswordMutation();
  useEffect(() => {
    const verify = async () => {
      const response = await verifyOtp({ email: email, otp: otp });
      if (response?.error) {
        enqueueSnackbar('InValid', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        navigate('/forget');
      }
    };

    if (!email || !otp) {
      navigate('/forget');
    } else {
      verify();
    }
  }, [email, otp]);
  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { validateForm, setErrors }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleSubmit(values);
        } else {
          setErrors(errors);
        }
      });
    },
  });
  const handleSubmit = async (values) => {
    let body = {
      email: email,
      ...values,
    };
    const response = await changePassword(body);
    console.log(response);
    if (response?.data) {
      enqueueSnackbar('Password reset successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      navigate('/');
    } else if (response?.error) {
      if (typeof response?.error?.data?.detail === 'string') {
        enqueueSnackbar('Error while resetting password! Try again', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        navigate('/forget');
      } else {
        enqueueSnackbar('Errors! Try again', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        navigate('/forget');
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '8%',
        }}
      >
        <Grid
          container
          sx={{
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
              <Card
                sx={{
                  background: '#07166cb8',
                  color: '#fff !important',
                  borderRadius: mobileView ? '0% !important' : '6% !important',
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <CardContent>
                    <TitleBox>Altel Admin</TitleBox>
                    <Typography variant="h5">Reset Password</Typography>
                    <Box sx={{ marginTop: '12px' }}>
                      {/* <CustomTextLabel>Last Name</CustomTextLabel> */}
                      <CustomTextField
                        autoComplete="off"
                        placeholder="Enter New Password"
                        name="new_password"
                        id="new_password"
                        label={'New Password'}
                        InputLabelProps={{
                          sx: {
                            color: '#fff !important',
                          },
                        }}
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <CustomInputAdornment position="start">
                              <PasswordIcon />
                            </CustomInputAdornment>
                          ),
                          endAdornment: (
                            <CustomInputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                sx={{
                                  color: '#fff',
                                }}
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </CustomInputAdornment>
                          ),
                        }}
                        value={formik.values.new_password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.new_password &&
                          Boolean(formik.errors.new_password)
                        }
                        helperText={
                          formik.touched.new_password
                            ? formik.errors.new_password
                            : ''
                        }
                      />
                    </Box>
                    <Box sx={{ marginTop: '12px' }}>
                      {/* <CustomTextLabel>Email</CustomTextLabel> */}
                      <CustomTextField
                        autoComplete="off"
                        placeholder="Enter Confirm Password"
                        name="confirm_password"
                        id="confirm_password"
                        label={'Confirm Password'}
                        InputLabelProps={{
                          sx: {
                            color: '#fff !important',
                          },
                        }}
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                          startAdornment: (
                            <CustomInputAdornment position="start">
                              <PasswordIcon />
                            </CustomInputAdornment>
                          ),
                          endAdornment: (
                            <CustomInputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                sx={{
                                  color: '#fff',
                                }}
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </CustomInputAdornment>
                          ),
                        }}
                        value={formik.values.confirm_password || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirm_password &&
                          Boolean(formik.errors.confirm_password)
                        }
                        helperText={
                          formik.touched.confirm_password
                            ? formik.errors.confirm_password
                            : ''
                        }
                      />
                    </Box>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <CustomLoginButton type="submit">
                      Reset Password
                    </CustomLoginButton>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={verfiyOtpLoading || changePasswordLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ResetPassword;
