import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  CustomInputAdornment,
  CustomLoginButton,
  CustomTextField,
  CustomTextLabel,
  TitleBox,
} from '../../../Utils/StyleComponents';
import EmailIcon from '@mui/icons-material/Email';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  useVerfiyEmailMutation,
  useVerfiyOtpMutation,
} from '../../../Services/UserApi';
import { useSnackbar } from 'notistack';

export const ForgetPassword = () => {
  const [otp, setOtp] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [seconds, setSeconds] = useState(30);
  const navigate = useNavigate();
  const [verifyEmail, { isLoading }] = useVerfiyEmailMutation();
  const [verifyOtp, { isLoading: verfiyOtpLoading }] = useVerfiyOtpMutation();
  const mobileView = useMediaQuery('(max-width: 600px)');
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timerId); // Cleanup the timer
    }
  }, [seconds]);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
  });
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values, { validateForm, setErrors }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleForgetPassword(values, setErrors);
        } else {
          setErrors(errors);
        }
      });
    },
  });
  const handleForgetPassword = async (formValues, setErrors) => {
    const response = await verifyEmail(formValues);
    if (response?.data) {
      enqueueSnackbar(
        'We have sent an OTP to your email address. Please check your inbox.',
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        }
      );
      setTempEmail(formValues?.email);
      setIsEmailVerified(true);
      setSeconds(30);
    } else if (response?.error) {
      if (typeof response?.error?.data?.detail === 'string') {
        setErrors({ email: response?.error?.data?.detail });
        enqueueSnackbar(response?.error?.data?.detail || '', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        enqueueSnackbar('error while submitting', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
  };

  const handleResendOtp = async () => {
    const response = await verifyEmail({ email: tempEmail });
    if (response?.data) {
      enqueueSnackbar(
        'We have sent an OTP to your email address. Please check your inbox.',
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        }
      );
      setSeconds(30);
    } else if (response?.error) {
      enqueueSnackbar(response?.error?.data?.detail || '', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };

  const handleVerify = async () => {
    const response = await verifyOtp({ email: tempEmail, otp: otp });
    if (response?.data) {
      navigate('/reset', {
        state: { email: tempEmail, otp: otp },
      });
    } else if (response?.error) {
      if (typeof response?.error?.data?.detail === 'string') {
        enqueueSnackbar(response?.error?.data?.detail || '', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        enqueueSnackbar('error accoured in otp  verification ', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    }
  };

  const validateChar = (value) => {
    return !isNaN(Number(value));
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '8%',
        }}
      >
        <Grid
          container
          sx={{
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            <Grid item xs={12} sm={5} md={3} lg={3} xl={3}>
              <Card
                sx={{
                  background: '#07166cb8',
                  color: '#fff !important',
                  borderRadius: mobileView ? '0% !important' : '6% !important',
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <CardContent>
                    <TitleBox>Altel Admin</TitleBox>
                    <Typography variant="h5">Forgot password ?</Typography>
                    <Typography>
                      Enter the email address you registered with
                    </Typography>
                    <Box>
                      {/* <CustomTextLabel>Email</CustomTextLabel> */}
                      <CustomTextField
                        sx={{
                          '& .Mui-disabled': {
                            color: 'grey !important',
                            WebkitTextFillColor: 'grey !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'grey !important',
                            },
                            '& .MuiInputAdornment-root': {
                              color: 'grey !important',
                            },
                          },
                        }}
                        autoComplete="off"
                        placeholder="Enter Email"
                        name="email"
                        type="email"
                        InputProps={{
                          startAdornment: (
                            <CustomInputAdornment position="start">
                              <EmailIcon />
                            </CustomInputAdornment>
                          ),
                          sx: {
                            '& .Mui-disabled': {
                              '-webkit-text-fill-color': '#grey !important',
                            },
                          },
                        }}
                        value={formik.values.email || tempEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                        disabled={isEmailVerified}
                      />
                    </Box>
                    {isEmailVerified && (
                      <Box sx={{ marginTop: '1rem' }}>
                        <CustomTextLabel>
                          One-time Passcode (OTP)
                        </CustomTextLabel>
                        <MuiOtpInput
                          length={6}
                          name="otp"
                          value={otp}
                          TextFieldsProps={{
                            placeholder: '-',
                          }}
                          validateChar={validateChar}
                          onChange={handleChange}
                          sx={{
                            '& input': {
                              color: '#fff !important',
                              padding: '10px 0px !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#fff !important',
                            },
                          }}
                        />
                        {seconds > 0 ? (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#fff',
                              textAlign: 'end',
                              mt: 2,
                              cursor: 'pointer',
                            }}
                          >
                            Resend Otp in ({seconds} Sec)
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#2496D8',
                              textAlign: 'end',
                              mt: 2,
                              cursor: 'pointer',
                            }}
                            onClick={handleResendOtp}
                          >
                            Resend Otp
                          </Typography>
                        )}
                      </Box>
                    )}
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    {!isEmailVerified ? (
                      <CustomLoginButton type="submit">
                        Reset Password
                      </CustomLoginButton>
                    ) : (
                      <CustomLoginButton type="button" onClick={handleVerify}>
                        Continue
                      </CustomLoginButton>
                    )}
                  </CardActions>
                </form>
                <Typography textAlign="start" sx={{ margin: '20px' }}>
                  Remember password!{' '}
                  <Button
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#2496D8',
                    }}
                    onClick={() => navigate('/login')}
                  >
                    Log in.
                  </Button>
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || verfiyOtpLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
