const theme = {
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {},
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          textcolor: {
            main: '#000',
          },
          backButton: {
            main: '#5E5CEB',
          },
          appbarcolor: {
            main: '#F7F6FB',
          },
          iconscolor: {
            main: '#5E5CEB',
          },
          avtarcolor: {
            main: '#D7D7F8',
          },
          primary: {
            light: '#5785A6',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
          },
          secondary: {
            light: '#fff',
            main: '#F33854',
            dark: '#F33854',
            contrastText: '#fff',
          },
          divider: {
            main: 'rgba(0, 0, 0, 0.12)',
          },
          background: {
            // default: "#EAEAF6",
            default: '#F3F2F3',
            paper: '#fff',
            subpaper: '#EAEAF6',
          },
          inputcolor: {
            main: '#000000',
          },
          alerticon: {
            main: '#5E5CEB',
          },
          registerAlerticon: {
            main: '#ff1b1b',
          },
          tabcolor: {
            main: '#111827',
          },
          dialogMessageText: {
            main: '#4a4a4a',
          },
          sendtext: {
            main: '#5E5CEB',
          },
          confirmationtext: {
            main: '#7c7c7c',
          },
          dividercolor: {
            main: '#dddddd',
          },
          inputListTitle: {
            main: '#292929',
          },
          inputDateText: {
            main: '#858585',
          },
          savedText: {
            main: '#050450',
          },
          text: {
            primary: '#01004D',
            secondary: '#363636',
            customGrey1: '#474747',
            customNavlink2: '#111827',
          },
          publicBackground: {
            main: '#fff',
          },
          welcomeText: {
            main: '#2B2B2B',
          },
          loginDescription: {
            main: '#1b1b1b',
          },
          linkText: {
            main: '#002864',
          },
          singupColor: {
            main: '#67676f',
          },
          forgotText: {
            main: '#222222',
          },
          registerNoteText: {
            main: '##D2E7FF',
          },
          llmText: {
            main: '#000',
          },
          alertdialog: {
            main: '#fff',
          },
          historyBackground: {
            main: '#f0f8ff',
          },
          historyItemBorder: {
            main: '#dcefff',
          },
          historyQuestionText: {
            main: '#000',
          },
          historyQuestionPrompt: {
            main: '#000',
          },
          historySidebarBorder: {
            main: '#a792fd',
          },
          historyListConatiner: {
            main: '#e5e0f7',
          },
          historyListConatinerHovered: {
            main: '#c5b4f7',
          },
          historyListOptionsConatiner: {
            main: '#b399ff',
          },
          historyThreadContainer: {
            main: '#e5e0f7',
          },
          historyThreadContainerHovered: {
            main: '#c5b4f7',
          },
          fileBox: {
            main: '#D7D7F8',
          },
          chartColor: {
            main: '#8C8C8C',
          },
          fileSixeColor: {
            main: '#666',
          },
          DropFile: {
            main: '#F8F9FA',
          },
          cardBackground: {
            main: '#fff',
          },
          cloudIcon: {
            main: '#55C7FF',
          },
          greencolor: {
            main: '#209020',
          },
          suggestionCard: {
            main: '#F0F4F9',
          },
          historyCategoryTitle: {
            main: '#6c42ff',
          },
          alertSubHeading: {
            main: '#7c7c7c',
          },
        }
      : {
          // palette values for dark mode
          textcolor: {
            main: '#fff',
          },
          backButton: {
            main: '#fff',
          },
          appbarcolor: {
            main: '#283047',
          },
          iconscolor: {
            main: '#fff',
          },
          avtarcolor: {
            main: '#D9D9D9',
          },
          primary: {
            light: '#5E5CEB',
            main: '#5E5CEB',
            dark: '#002884',
            contrastText: '#fff',
          },
          secondary: {
            light: '#fff',
            main: '#00642E',
            dark: '#00642E',
            contrastText: '#fff',
          },
          divider: {
            main: '#fff',
          },
          background: {
            default: '#171D31',
            paper: '#283046',
            subpaper: '#31375A',
          },
          tabcolor: {
            main: '#fff',
          },
          alerticon: {
            main: '#fff',
          },
          registerAlerticon: {
            main: '#fff',
          },
          inputcolor: {
            main: '#fff',
          },
          dialogMessageText: {
            main: '#fff',
          },
          sendtext: {
            main: '#fff',
          },
          confirmationtext: {
            main: '#fff',
          },
          dividercolor: {
            main: '#fff',
          },
          inputListTitle: {
            main: '#fff',
          },
          inputDateText: {
            main: '#fff',
          },
          savedText: {
            main: '#fff',
          },
          text: {
            primary: '#F4F4F4',
            secondary: '#F2F2F2',
          },
          publicBackground: {
            main: '#171D31',
          },
          welcomeText: {
            main: '#fff',
          },
          loginDescription: {
            main: '#fff',
          },
          linkText: {
            main: '#fff',
          },
          singupColor: {
            main: '#fff',
          },
          forgotText: {
            main: '#fff',
          },
          registerNoteText: {
            main: '#283046',
          },
          llmText: {
            main: '#00ff5a',
          },
          alertdialog: {
            main: '#303030',
          },
          historyBackground: {
            main: '#f0f8ff',
          },
          historyItemBorder: {
            main: '#dcefff',
          },
          historyQuestionText: {
            main: '#fff',
          },
          historyQuestionPrompt: {
            main: '#fff',
          },
          historySidebarBorder: {
            main: '#fff',
          },
          historyListOptionsConatiner: {
            main: '#252e4e',
          },
          historyListConatiner: {
            main: '#252e4e',
          },
          historyListConatinerHovered: {
            main: '#171d31',
          },
          historyThreadContainer: {
            main: '#253362',
          },
          historyThreadContainerHovered: {
            main: '#182349',
          },
          fileBox: {
            main: '#283046',
          },
          chartColor: {
            main: '#fff',
          },
          fileSixeColor: {
            main: '#fff',
          },
          DropFile: {
            main: '#31375A',
          },
          cardBackground: {
            main: '#333B4F',
          },
          cloudIcon: {
            main: '#fff',
          },
          greencolor: {
            main: '#8dff80',
          },
          suggestionCard: {
            main: '#283046',
          },
          historyCategoryTitle: {
            main: '#fff',
          },
          alertSubHeading: {
            main: '#fff',
          },
        }),
  },
});

export default theme;
