import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Slide,
  useMediaQuery,
  Badge,
} from '@mui/material';
import {
  CustomFab,
  CustomModalTitle,
  NotificationCount,
  CustomTitle,
} from '../Utils/StyleComponents';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNotification,
  updateNotificationInfo,
} from '../Store/clientSlice';
import NotificationsIcon from '@mui/icons-material/Notifications';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DividedList = () => {
  const [openIndex, setOpenIndex] = React.useState(null);
  const notifications = useSelector((state) => state?.client?.notifications);
  const dispatch = useDispatch();
  const handleChange = (index, ele) => {
    setOpenIndex(openIndex === index ? false : index);
    if (!ele?.read) {
      dispatch(updateNotificationInfo(ele));
    }
  };
  return (
    <>
      <Box
        sx={{
          justifyContent: 'center',
          gap: 4,
          maxWidth: '400px',
          maxHeight: '400px',
        }}
      >
        {notifications?.map((ele, index) => (
          <Accordion
            key={`${ele?.id}-${index}`}
            expanded={openIndex === index}
            sx={{
              background: ele?.read ? 'aliceblue' : '#1212124d',
              color: '#000000',
            }}
            onChange={() => handleChange(index, ele)}
          >
            <AccordionSummary
              aria-controls={`panel${index}-content`}
              id={index}
            >
              <Typography>
                {ele?.endpointName}-{ele?.lastActionDateTime}-{ele?.action}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: 'lightgrey' }}>
              {Object.keys(ele).map((key, index) => {
                if (
                  ![
                    'endpointName',
                    'lastActionDateTime',
                    'action',
                    'read',
                  ].includes(key)
                ) {
                  return (
                    <Typography variant="subtitle1" key={index}>
                      <strong>{key}:</strong> {ele[key]}
                    </Typography>
                  );
                }
              })}
            </AccordionDetails>
          </Accordion>
        ))}
        {notifications?.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            There are no new notifications at this time.
          </div>
        )}
      </Box>
    </>
  );
};

const Notification = () => {
  const [open, setOpen] = React.useState(false);
  const notifications = useSelector((state) => state?.client?.notifications);
  const mobileView = useMediaQuery('(max-width: 600px)');
  const dispatch = useDispatch();

  const handleClearAll = () => {
    dispatch(clearNotification());
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
        }}
      >
        <CustomFab onClick={handleClickOpen}>
          {mobileView ? (
            <>
              <Badge
                badgeContent={
                  notifications?.filter((ele) => ele?.read === false).length | 0
                }
                showZero
              >
                <NotificationsIcon />
              </Badge>
            </>
          ) : (
            <>
              Notifications
              <NotificationCount>
                {notifications?.filter((ele) => ele?.read === false).length}
              </NotificationCount>
            </>
          )}
        </CustomFab>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <CustomModalTitle>
          <CustomTitle>Notifications</CustomTitle>
          <Button onClick={handleClearAll}>Clear All</Button>
        </CustomModalTitle>
        <Divider />
        <DialogContent>
          <DividedList />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Notification;
