import * as yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const settingSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
});

export const passwordSchema = yup.object().shape({
  old_password: yup.string().required('Current Password is Required'),
  new_password: yup
    .string()
    .matches(
      passwordRules,
      'password should contain uppercase letters, lowercase letters, numbers, and at least one special character'
    )
    .required('New Password is Required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .matches(
      passwordRules,
      'password should contain uppercase letters, lowercase letters, numbers, and at least one special character'
    )
    .required('Password is Required'),
});

export const ResetPasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .matches(
      passwordRules,
      'password should contain uppercase letters, lowercase letters, numbers, and at least one special character'
    )
    .required('Password is Required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .matches(
      passwordRules,
      'password should contain uppercase letters, lowercase letters, numbers, and at least one special character'
    )
    .required('Password is Required'),
});
