import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  clientInfo: null,
  tokens: {},
  message: {},
};

export const userSlice = createSlice({
  name: 'user-info',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      };
    },

    updateUserTokens: (state, action) => {
      state.tokens = {
        access: action.payload?.access_token,
        refresh: action.payload?.refresh_token,
      };
    },

    updateUserDetails: (state, action) => {
      if (action.payload) {
        state.userInfo = {
          ...action.payload,
        };
      } else {
        state.userInfo = null;
        state.clientInfo = null;
      }
    },

    updateClientInfo: (state, action) => {
      if (action.payload) {
        state.clientInfo = action.payload;
      } else {
        state.clientInfo = [];
      }
    },

    updateMessage: (state, action) => {
      if (action.payload) {
        state.message = action.payload;
      } else {
        state.message = '';
      }
    },
  },
});

export const {
  updateUserDetails,
  updateUserTokens,
  updateUserInfo,
  updateClientInfo,
  updateMessage,
} = userSlice.actions;

export default userSlice.reducer;
