import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: `/login`,
        method: 'POST',
        body,
      }),
    }),
    getRefreshToken: builder.mutation({
      query: (token) => ({
        url: '/token/refresh/',
        method: 'POST',
        body: {
          refresh: token,
        },
      }),
    }),
    verfiyEmail: builder.mutation({
      query: (body) => ({
        url: '/admins/forgot-password/',
        method: 'POST',
        body,
      }),
    }),
    verfiyOtp: builder.mutation({
      query: (body) => ({
        url: '/admins/verify-otp/',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: '/admins/reset-password/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useGetRefreshTokenMutation,
  useVerfiyEmailMutation,
  useVerfiyOtpMutation,
  useResetPasswordMutation,
} = userApi;
