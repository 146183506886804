import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import propTypes from 'prop-types';
import {
  CustomCopyBox,
  CustomErrorBox,
  CustomModalTitle,
  CustomPaper,
  CustomTitle,
} from '../Utils/StyleComponents';
import { Box, Typography } from '@mui/material';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
export default function HostDetailModal(props) {
  // const appData = props?.data;
  const [copyMessage, SetCopyMessage] = React.useState('copy');
  const handleCopy = () => {
    const jsonData = JSON.stringify(props.data, null, 2); // Pretty print JSON
    navigator.clipboard
      .writeText(jsonData)
      .then(() => {
        SetCopyMessage('copied!');
        setTimeout(() => {
          SetCopyMessage('copy');
        }, 3000); // Clear the message after 3 seconds
      })
      .catch((err) => {
        console.error('Failed to copy JSON data to clipboard', err);
      });
  };
  return (
    <React.Fragment>
      <Dialog
        open={props?.show}
        onClose={() => props?.setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomModalTitle>
          <CustomTitle>
            <Box>
              Agent Details:{' '}
              <span style={{ textWrap: 'nowrap' }}>
                {props?.data?.endpointName}
              </span>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '2px',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="subtitle1" sx={{ textWrap: 'nowrap' }}>
                <strong>status :</strong> {props?.data?.status}
              </Typography>
              <Typography variant="subtitle1" sx={{ textWrap: 'nowrap' }}>
                <strong>action :</strong> {props?.data?.action}
              </Typography>
            </Box>
          </CustomTitle>
          <Button
            sx={{ margin: 'auto 0', width: 'fit-content' }}
            onClick={() => props?.setShowModal(false)}
          >
            x
          </Button>
        </CustomModalTitle>
        <DialogContent>
          {props?.hostDataResponse?.error && (
            <CustomErrorBox>
              {props?.hostDataResponse?.error?.code}:
              {props?.hostDataResponse?.error?.message}
            </CustomErrorBox>
          )}
          <CustomPaper elevation={3}>
            <CustomCopyBox>
              <Button
                sx={{
                  minWidth: 'fit-content',
                  padding: '0px !important',
                  width: 'fit-content',
                  alignSelf: 'center',
                }}
                onClick={handleCopy}
              >
                <ContentCopyTwoToneIcon />
              </Button>
              <p style={{ textAlign: 'center', width: '55px', margin: '0' }}>
                {copyMessage}
              </p>
            </CustomCopyBox>
            {props?.data &&
              Object.keys(props?.data).map((key, index) => (
                <Typography variant="subtitle1" key={index}>
                  <strong>{key}:</strong> {props?.data[key]}
                </Typography>
              ))}
          </CustomPaper>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
HostDetailModal.propTypes = {
  data: propTypes.objectOf(propTypes.any),
  show: propTypes.bool,
  setShowModal: propTypes.func,
  hostDataResponse: propTypes.objectOf(propTypes.any),
};
