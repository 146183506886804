import { createApi } from '@reduxjs/toolkit/query/react';
import AuthenticatedBaseQuery from './AuthenticatedQuery';

export const ClientApi = createApi({
  reducerPath: 'ClientApi',
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({
        url: '/get_clients/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetClientsQuery } = ClientApi;
