import {
  Backdrop,
  Box,
  CardActions,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  CustomInputAdornment,
  CustomLoginButton,
  CustomTextField,
} from '../../../Utils/StyleComponents';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { settingSchema } from '../../../Utils/ValidationSchemas';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { useUpdateUserDetailsMutation } from '../../../Services/ProfileApi';
import { useSnackbar } from 'notistack';
import { updateUserInfo } from '../../../Store/userSlice';

const ProfileForm = () => {
  const user = useSelector((state) => state?.user?.userInfo);
  const dispatch = useDispatch();
  const [UpdateUser, { isLoading }] = useUpdateUserDetailsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
    },
    validationSchema: settingSchema,
    onSubmit: (values, { validateForm, setErrors, resetForm }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleSubmit(values, resetForm);
        } else {
          setErrors(errors);
        }
      });
    },
  });

  const handleSubmit = async (values, resetForm) => {
    const response = await UpdateUser(values);
    try {
      if (response?.data) {
        dispatch(updateUserInfo(response?.data));
        resetForm({ values });
        enqueueSnackbar('Profile Data Updated', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    } catch {
      enqueueSnackbar('error accured while updating', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Box>
            {/* <CustomTextLabel>First Name</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter First Name"
              name="first_name"
              id="first_name"
              label={'First Name'}
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              InputProps={{
                sx: { '&.Mui-focused': { borderColor: '#fff !important' } },
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <PersonIcon />
                  </CustomInputAdornment>
                ),
              }}
              value={formik.values?.first_name || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors?.first_name)}
              helperText={formik.errors?.first_name}
            />
          </Box>
          <Box sx={{ marginTop: '12px' }}>
            {/* <CustomTextLabel>Last Name</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter Last Name"
              label="Last Name"
              name="last_name"
              InputProps={{
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <PersonIcon />
                  </CustomInputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              value={formik.values?.last_name || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors?.last_name)}
              helperText={formik.errors?.last_name}
            />
          </Box>
          <Box sx={{ marginTop: '12px' }}>
            {/* <CustomTextLabel>Email</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter Email"
              label="Email (read only)"
              name="email"
              type="email"
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              InputProps={{
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <EmailIcon />
                  </CustomInputAdornment>
                ),
                readOnly: true,
              }}
              value={formik.values?.email || ''}
            />
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <CustomLoginButton type="submit">Update Profile</CustomLoginButton>
        </CardActions>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProfileForm;
