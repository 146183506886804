import {
  Backdrop,
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {
  CustomInputAdornment,
  CustomLoginButton,
  CustomTextField,
} from '../../../Utils/StyleComponents';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { passwordSchema } from '../../../Utils/ValidationSchemas';
import { useFormik } from 'formik';
import { useState } from 'react';
import PasswordIcon from '@mui/icons-material/Password';
import { useUpdateUserPasswordMutation } from '../../../Services/ProfileApi';
import { useSnackbar } from 'notistack';

const SecurityForm = () => {
  const [UpdateUserPassword, { isLoading }] = useUpdateUserPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: passwordSchema,
    onSubmit: (values, { validateForm, setErrors, resetForm }) => {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleSubmit(values);
        } else {
          setErrors(errors);
        }
      });
      resetForm();
    },
  });
  const handleSubmit = async (values) => {
    const response = await UpdateUserPassword(values);
    try {
      if (response?.data) {
        enqueueSnackbar(response?.data?.status || '', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else if (response?.error) {
        enqueueSnackbar(response.error?.data?.detail || 'Invalid', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    } catch {
      enqueueSnackbar('error while updating password', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Box>
            {/* <CustomTextLabel>First Name</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter Old Password"
              name="old_password"
              id="old_password"
              label={'Old Password'}
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              type={showOldPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <PasswordIcon />
                  </CustomInputAdornment>
                ),
                endAdornment: (
                  <CustomInputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      sx={{
                        color: '#fff',
                      }}
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </CustomInputAdornment>
                ),
              }}
              value={formik.values?.old_password || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.new_password &&
                Boolean(formik.errors?.old_password)
              }
              helperText={
                formik.touched?.new_password ? formik.errors?.old_password : ''
              }
            />
          </Box>
          <Box sx={{ marginTop: '12px' }}>
            {/* <CustomTextLabel>Last Name</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter New Password"
              name="new_password"
              id="new_password"
              label={'New Password'}
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <PasswordIcon />
                  </CustomInputAdornment>
                ),
                endAdornment: (
                  <CustomInputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      sx={{
                        color: '#fff',
                      }}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </CustomInputAdornment>
                ),
              }}
              value={formik.values?.new_password || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.new_password &&
                Boolean(formik.errors?.new_password)
              }
              helperText={
                formik.touched?.new_password ? formik.errors?.new_password : ''
              }
            />
          </Box>
          <Box sx={{ marginTop: '12px' }}>
            {/* <CustomTextLabel>Email</CustomTextLabel> */}
            <CustomTextField
              autoComplete="off"
              placeholder="Enter Confirm Password"
              name="confirm_password"
              id="confirm_password"
              label={'Confirm Password'}
              InputLabelProps={{
                sx: {
                  color: '#fff !important',
                },
              }}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <CustomInputAdornment position="start">
                    <PasswordIcon />
                  </CustomInputAdornment>
                ),
                endAdornment: (
                  <CustomInputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      sx={{
                        color: '#fff',
                      }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </CustomInputAdornment>
                ),
              }}
              value={formik.values?.confirm_password || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched?.confirm_password &&
                Boolean(formik.errors?.confirm_password)
              }
              helperText={
                formik.touched?.confirm_password
                  ? formik.errors?.confirm_password
                  : ''
              }
            />
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <CustomLoginButton type="submit">Update Password</CustomLoginButton>
        </CardActions>
      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SecurityForm;
