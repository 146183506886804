import React, { useEffect, useState } from 'react';
import {
  Box,
  useMediaQuery,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { ItemButton, TitleBox } from '../Utils/StyleComponents';
import Notification from './Notification';
import CustomBreadCrumps from './Components/CustomBreadCrumps';
import { useSelector } from 'react-redux';
import {
  useInventoryControlMutation,
  useSophusMutation,
  useTrendWorkloadMutation,
  useVicariusMutation,
} from '../Services/UnacemApi';
import { useSnackbar } from 'notistack';

const AppList = () => {
  const location = useLocation();
  const { Client } = location.state || {};
  const App = useSelector((state) =>
    state?.user?.clientInfo.find((ele) => ele.title === Client)
  );
  const navigate = useNavigate();
  const [sophusResponse] = useSophusMutation();
  const [inventoryResponse] = useInventoryControlMutation();
  const [vicariusResponse] = useVicariusMutation();
  const [trendWorkloadResponse] = useTrendWorkloadMutation();
  const mobileView = useMediaQuery('(max-width: 600px)');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!Client) {
      navigate('/');
    }
  }, [Client, navigate]);

  const handleApiResponse = async (data, apiCall) => {
    setIsLoading(true);
    try {
      const response = await apiCall(data).unwrap();
      if (response?.status) {
        enqueueSnackbar(response.status, {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      } else {
        enqueueSnackbar('Something Went Wrong', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
      }
    } catch (error) {
      enqueueSnackbar(error?.data?.detail, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async (ele) => {
    if (
      ele?.endpoints?.length == 0 ||
      !Object.keys(ele).includes('endpoints')
    ) {
      enqueueSnackbar('Permission Denied', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      return;
    }
    let data = { client: Client, app: ele.title };
    switch (ele.title) {
      case 'Sophos':
        await handleApiResponse(data, sophusResponse);
        break;
      case 'Vicarius':
        await handleApiResponse(data, vicariusResponse);
        break;
      case 'CER-Trend Micro':
      case 'Serviandina-Trend Micro':
      case 'Mavesa-Trend Micro':
      case 'Plasticacho-Trend Micro':
      case 'TPG-Trend Micro':
      case 'Interoc-Trend Micro':
        navigate('/Client/App', { state: { Client: Client, AppId: ele.id } });
        break;
      case 'Trend Workload':
        await handleApiResponse(data, trendWorkloadResponse);
        break;
      default:
        enqueueSnackbar('In Progress', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        break;
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      const response = await inventoryResponse().unwrap();

      // Create a URL for the blob and determine the file type
      const url = window.URL.createObjectURL(response);

      // Generate current date and time for the filename in a readable format
      const pad = (num) => String(num).padStart(2, '0');
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}_${pad(now.getHours())}-${pad(now.getMinutes())}-${pad(now.getSeconds())}`;
      const filename = `unacem_${timestamp}.zip`;

      // Create an anchor element and trigger the download
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // message to show on pop up
      enqueueSnackbar('File Downloaded Successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } catch (error) {
      enqueueSnackbar('Something Went Wrong', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDashboardButtonClick = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <CustomBreadCrumps />
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          sx={{
            width: mobileView ? '100%' : '74% !important',
            margin: 'auto !important',
          }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TitleBox>{Client}</TitleBox>
          </Grid>
          {App?.AppList?.map((ele, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <ItemButton onClick={() => handleClick(ele)}>
                {ele.title.includes('-') ? ele.title.split('-')[1] : ele.title}
              </ItemButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      {Client != 'UNACEM' ? (
        <Notification />
      ) : (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            right: '1rem',
            gap: 5,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            height: '62vh',
            padding: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: '#2496D8', height: '50px' }}
            onClick={handleButtonClick}
          >
            Inventory Control
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#2496D8', height: '50px' }}
            onClick={handleDashboardButtonClick}
          >
            Dashboard
          </Button>
        </Box>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AppList;
