import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
};

export const clientSlice = createSlice({
  name: 'client-info',
  initialState,
  reducers: {
    updateNotification: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },
    updateNotificationInfo: (state, action) => {
      state.notifications = state.notifications.map((ele) => {
        if (ele?.message === action.payload.message) {
          ele.read = true;
        }
        return ele;
      });
    },
    // eslint-disable-next-line no-unused-vars
    clearNotification: (state, action) => {
      state.notifications = [];
    },
  },
});

export const { updateNotification, updateNotificationInfo, clearNotification } =
  clientSlice.actions;

export default clientSlice.reducer;
