import { createTheme } from '@mui/material';
import React, { useState } from 'react';
import { getDesignTokens } from './theme';

export const useColorTheme = () => {
  const themeMode = null;
  const [mode, setMode] = useState(themeMode || 'light');

  const toggleColorMode = () =>
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));

  const modifiedTheme = React.useMemo(
    () => createTheme(getDesignTokens(mode)),
    [mode]
  );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};
