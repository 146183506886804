import { createApi } from '@reduxjs/toolkit/query/react';
import AuthenticatedBaseQuery from './AuthenticatedQuery';

export const TrendMicroApi = createApi({
  reducerPath: 'TrendMicroApi',
  baseQuery: AuthenticatedBaseQuery,
  endpoints: (builder) => ({
    getAdminPermission: builder.query({
      query: (body) => ({
        url: `/get_permission/${body.app}`,
        method: 'GET',
      }),
    }),
    isolate: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/isolate_endpoint/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    restore: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/restore_endpoint/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    collectFile: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/collect_file_endpoint`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    terminateProcess: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/terminate_process_endpoint`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    startMalwareScan: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/malware_scan_endpoint`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    endProcessList: builder.mutation({
      query: (body) => ({
        url: `/trend_micro/search_activities_endpoint`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAdminPermissionQuery,
  useIsolateMutation,
  useRestoreMutation,
  useCollectFileMutation,
  useEndProcessListMutation,
  useTerminateProcessMutation,
  useStartMalwareScanMutation,
} = TrendMicroApi;
